import auth from './authService';
import {endPoint} from '../../config.json';

export const getSurveys = async (sertificate, id, fromDate, toDate) => {
    const res = await fetch(endPoint + `Surveys&Arguments=-N1,-A${sertificate},-N${id},-A${fromDate},-A${toDate}&CTX=` + auth.getCTX());
    const data = await res.json();
    return data;
};

export const printSurvey = async (sertificate, id, CustomerID) => {
    const res = await fetch(endPoint + `Surveys&Arguments=-N2,-A${id},-N${sertificate},-A,-A,-N${CustomerID}`);
    const data = await res.blob();   // Receive PDF Steam as as Blob
    const file = new Blob([data], {type: 'application/pdf'});  //Create a Blob from the PDF Stream
    const fileURL = URL.createObjectURL(file);  //Build a URL from the file
    window.open(fileURL);  //Open the URL on new Window
};
