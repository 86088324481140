import React, { Fragment, Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import auth from './components/services/authService';
import Navbar from './components/layout/Navbar';
import NotFound from './components/pages/NotFound';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Login from './components/pages/Login';
import Logout from './components/pages/Logout';
import Profile from './components/pages/Profile';
import Clients from './components/clients/Clients';
import ClientForm from './components/clients/ClientForm';
import Surveys from './components/surveys/Surveys';

import ProtectedRoute from './components/common/protectedRoute';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';


class App extends Component {
  state = {};

  async componentDidMount() {
    if (auth.getCTX()) {
      const user = await auth.getCurrentUser();
      this.setState({ user });
    };
  };

  handleChangeProfile = async () => {
    const user = await auth.getCurrentUser();
    this.setState({ user });
  };

  render() {
    const { user } = this.state;
    return (
      <Router>
        <Fragment>
          <ToastContainer />
          <Navbar user={user} />
          <main className='container'>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route path='/login' component={Login} />
              <Route path='/logout' component={Logout} />
              <Route path='/about' component={About} />
              <ProtectedRoute path='/surveys' render={props => <Surveys {...props} user={user || {}} />} />
              <ProtectedRoute path='/clients/:id' component={ClientForm} />
              <ProtectedRoute path='/clients' component={Clients} />
              <ProtectedRoute path='/profile' render={props => <Profile {...props} onChange={this.handleChangeProfile} />} />
              <Route path='/not-found' component={NotFound} />
              <Redirect to='/not-found' />
            </Switch>
          </main>
        </Fragment>
      </Router>
    );
  };
}

export default App;
