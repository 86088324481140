import React from 'react';
import Joi from 'joi-browser';
import Form from '../common/form';
import { getClient, saveClient } from '../services/clientService';

class ClientForm extends Form {

    state = {
        data: {
            IDCustomer: 0,
            CustomerName: '',
            ContactName: '',
            Address: '',
            Type: '',
            Remark: '',
            Phone1: '',
            Phone2: '',
            Phone3: ''
        },
        errors: {}
    };

    schema = {
        IDCustomer: Joi.number().required(),
        CustomerName: Joi.string().required().label('שם לקוח'),
        ContactName: Joi.string().required().label('איש קשר'),
        Address: Joi.string().allow(''),
        Type: Joi.string().allow(''),
        Remark: Joi.string().allow(''),
        Phone1: Joi.string().allow(''),
        Phone2: Joi.string().allow(''),
        Phone3: Joi.string().allow('')
    };

    async componentDidMount() {
        await this.populateClient();
    };

    async populateClient() {
        const clientID = this.props.match.params.id;
        if (clientID === 'new') return;
        const data = await getClient(clientID);
        if (data.length === 0) return this.props.history.replace('/not-found');
        this.setState({ data: this.mapToViewModel(...data) });  // spread operator because return array with one object
    };


    doSubmit = async () => {
        await saveClient(this.state.data);
        this.props.history.push('/clients');
    };

    doChange = () => { };

    handleChange = ({ currentTarget: input }) => {
        const data = { ...this.state.data };
        data[input.name] = input.value;
        this.setState({ data });
    };

    mapToViewModel(client) {
        return {
            IDCustomer: client.IDCustomer,
            CustomerName: client.CustomerName,
            ContactName: client.ContactName,
            Address: client.Address,
            Type: client.Type,
            Remark: client.Remark,
            Phone1: client.Telephon,
            Phone2: client.Cellular,
            Phone3: client.Telephone2
        };
    };


    render() {
        return (
            <div>
                {this.renderPlainText('Type')}
                <form onSubmit={this.handleSubmit}>  {/* handleSubmit in extended form component */}
                    {this.renderInput('CustomerName', 'שם')}
                    {this.renderInput('ContactName', 'איש קשר')}

                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <a className="btn btn-outline-secondary" href={'tel:' + this.state.data.Phone1}>טלפון ראשי</a>
                        </div>
                        <input value={this.state.data.Phone1} onChange={this.handleChange} name='Phone1'
                            type="text" className="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1" />
                    </div>

                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <a className="btn btn-outline-secondary" href={'tel:' + this.state.data.Phone2}>טלפון נייד</a>
                        </div>
                        <input value={this.state.data.Phone2} onChange={this.handleChange} name='Phone2'
                            type="text" className="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1" />
                    </div>

                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <a className="btn btn-outline-secondary" href={'tel:' + this.state.data.Phone3}>טלפון משני</a>
                        </div>
                        <input value={this.state.data.Phone3} onChange={this.handleChange} name='Phone3'
                            type="text" className="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1" />
                    </div>

                    {this.renderPlainText('Address')}

                    <div className="form-group">
                        <textarea rows='4' className="form-control-plaintext" readOnly name='Remark' onChange={this.handleChange} value={this.state.data.Remark}></textarea>
                    </div>

                    <div className="form-group">
                        {this.renderButton('שמור')}
                        <input className="btn btn-dark" type="button" value="חזרה" onClick={() => { this.props.history.push('/clients') }} />
                    </div>

                </form>

            </div>
        );
    };
};

export default ClientForm;