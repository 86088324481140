import React from 'react';
import Table from '../common/table';

class SurveysTable extends React.Component {

    columns = [
        { path: 'CheckDate', label: 'תאריך'},
        { path: 'CustomerName', label: 'לקוח'},
        { path: 'ID', label: 'תסקיר' },
        { path: 'CheckerName', label: 'בודק' },
        { key: 'print', content: survey => <button onClick={() => this.props.onPrint(survey)} className='btn btn-info btn-sm'><i className='fa fa-print'></i></button> }
    ];

    render () {
        const {surveys, onSort, sortColumn} = this.props;
        return (
            <Table columns = {this.columns}  data={surveys} onSort={onSort} sortColumn={sortColumn} idProperty='ID'/>
        );
    };

};


export default SurveysTable;