import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const Pagination = (props) => {
    const { itemsCount, pageSize, onPageChange, currentPage, onNext, onPrev, paginationRange, paginationStartFrom } = props;
    const pagesCount = Math.ceil(itemsCount / pageSize);
    if (pagesCount === 1) return null;
    const pages = _.range(paginationStartFrom, paginationRange + paginationStartFrom - 1);


    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination">
                <li className="page-item">
                    <button className="page-link" onClick={onPrev} >
                        <span aria-hidden="true">&laquo;</span>
                    </button>
                </li>
                {pages.map(
                    page => (
                        <li key={page} className={page === currentPage ? "page-item active" : "page-item"} >
                            <button className="page-link" onClick={() => { onPageChange(page) }} >{page}</button>
                        </li>
                    )
                )}
                <li className="page-item">
                    <button className="page-link" onClick={onNext} >
                        <span aria-hidden="true">&raquo;</span>
                    </button>
                </li>
            </ul>
        </nav>
    )
};


// type checking is good practies for reusable components
Pagination.propTypes = {
    itemsCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    onNext: PropTypes.func.isRequired,
    onPrev: PropTypes.func.isRequired,
    paginationRange: PropTypes.number.isRequired,
    paginationStartFrom: PropTypes.number.isRequired
}

export default Pagination;