import { toast } from 'react-toastify';
import { apiUrl, magicAppName, endPoint } from '../../config.json';

const CTX = 'CTX';


const logout = async () => {
    await fetch(endPoint + 'Auth&TRMCTX=Y&Arguments=-N3&CTX=' + getCTX());
    localStorage.removeItem(CTX);
};


const login = async (name, password) => {
    try {
        const formData = new FormData();
        formData.append('uname', name);
        formData.append('upass', password);
        formData.append('appname', magicAppName);
        formData.append('prgname', 'Auth');
        formData.append('Arguments', '-N1');
        const res = await fetch(apiUrl, {
            method: 'POST',
            body: formData
        });
        const data = await res.text();
        switch (data) {
            case 'NoUser':
                toast.error('משתמש לא קיים');
                break;
            case 'NoActiveUser':
                toast.error('משתמש לא פעיל');
                break;
            default:
                localStorage.setItem(CTX, data);
                window.location = '/';
        };
    }
    catch (err) {
        toast.error(err.message);
    };
}


const getCurrentUser = async () => {
    try {
        const res = await fetch(endPoint + 'Auth&CTX=' + getCTX() + '&Arguments=-N2');
        const data = await res.json();
        return data;
    }
    catch (err) {
        localStorage.removeItem(CTX);
        return null;
    };
};


const getCTX = () => {
    return localStorage.getItem(CTX) ? localStorage.getItem(CTX) : '';
};

const getCompanies = async () => {
    try {
        const res = await fetch(endPoint + 'INI&CTX=' + getCTX() + '&Arguments=-N2');
        const data = await res.json();
        return data;
    }
    catch (err) {
        localStorage.removeItem(CTX);
        return null;
    };
};


const setINI = async (companyId) => {
    try {
       await fetch (endPoint + 'INI&Arguments=-N1,-N' + companyId + '&CTX=' + getCTX());
    }
    catch (err) {
        localStorage.removeItem(CTX);
    };
};


export default { login, logout, getCurrentUser, getCTX, getCompanies, setINI };