import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from '../services/authService';

const ProtectedRoute = ({ component: Component, render, path, ...rest }) => {
    return (
        <Route {...rest}
            render={props => {
                if (!auth.getCTX())
                    return (<Redirect to='/login' />);
                return Component ? <Component {...props} /> : render(props);
            }}
        />
    );
};

export default ProtectedRoute;