import React from 'react';

const Home = () => {

    return (
        <div >
            <h1>דף בית</h1>
        </div>
    );
}


export default Home;