import React, { Component } from 'react';
import _ from 'lodash';
import { getClients } from '../services/clientService';
import { paginate } from '../utils/paginate';
import SearchBox from '../common/searchBox';
import Pagination from '../common/pagination';
import ClientsTable from './ClientsTable';


class Clients extends Component {

    state = {
        clients: [],
        searchQuery: '',
        pageSize: 8,
        currentPage: 1,
        paginationRange: 6,
        paginationStartFrom: 1,
        sortColumn: { path: 'CustomerName', order: 'asc' }
    };

    async componentDidMount() {
        const clients = await getClients();
        this.setState({ clients });
    };

    handleSerch = query => {
        this.setState({ searchQuery: query, currentPage: 1 });
    };

    handlePageChange = page => {
        this.setState({ currentPage: page });
    };

    handleSort = sortColumn => {
        this.setState({ sortColumn });
    }

    handleNext = () => {
        const { paginationStartFrom, paginationRange } = this.state;
        const nextPagination = paginationStartFrom + paginationRange - 1;
        this.setState({
            paginationStartFrom: nextPagination,
            currentPage: nextPagination
        });
    };


    handlePrev = () => {
        const { paginationStartFrom, paginationRange, currentPage } = this.state;
        if (currentPage > paginationRange - 1) {
            const prevPagination = paginationStartFrom - paginationRange + 1;
            this.setState({
                paginationStartFrom: prevPagination,
                currentPage: prevPagination
            });
        }
    };

    getPageData = () => {
        const { pageSize, currentPage, searchQuery, clients: allClients, sortColumn } = this.state;
        let filtered = allClients;
        if (searchQuery) {
            filtered = allClients.filter(client => client.CustomerName.toLowerCase().startsWith(searchQuery.toLowerCase()));
        };
        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
        const clients = paginate(sorted, currentPage, pageSize);
        return { totalCount: filtered.length, data: clients };
    };

    render() {
        const { length: count } = this.state.clients;
        if (count === 0) {
            return <p>אין לקוחות</p>;
        }
        const { pageSize, currentPage, searchQuery, paginationRange, paginationStartFrom, sortColumn } = this.state;
        const { totalCount, data: clients } = this.getPageData();

        return (
            <div className='row'>
                <div className='col'>
                    <p>סה"כ {totalCount} לקוחות</p>
                    <SearchBox value={searchQuery} onChange={this.handleSerch} />
                    <ClientsTable clients={clients} onSort={this.handleSort} sortColumn={sortColumn} />
                    <Pagination itemsCount={totalCount} pageSize={pageSize} onPageChange={this.handlePageChange}
                        currentPage={currentPage} paginationRange={paginationRange} onNext={this.handleNext} onPrev={this.handlePrev}
                        paginationStartFrom={paginationStartFrom} />
                </div>
            </div>
        );
    };
};

export default Clients;