import React from 'react';
import {Link} from 'react-router-dom';
import Table from '../common/table';

class ClientsTable extends React.Component {

    columns = [
        { path: 'CustomerName', label: 'לקוח', content: client => <Link to={`/clients/${client.IDCustomer}`}>{client.CustomerName}</Link>},
        { path: 'ContactName', label: 'איש קשר' },
        { key: 'phone', content: client => <a className='btn btn-info btn-sm' href={'tel:' + client.Telephon} ><i className='fa fa-phone'></i></a>},
        { key: 'cellular', content: client => <a className='btn btn-info btn-sm' href={'tel:' + client.Cellular} ><i className='fa fa-mobile'></i></a>}
    ];

    render () {
        const {clients, onSort, sortColumn} = this.props;
        return (
            <Table columns = {this.columns}  data={clients} onSort={onSort} sortColumn={sortColumn} idProperty='IDCustomer'/>
        );
    };

};


export default ClientsTable;