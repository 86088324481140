import React, { Fragment, useState } from 'react';
//import { Nav, Navbar, Form, FormControl, Button, NavDropdown } from 'react-bootstrap';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const NavBar = ({ user }) => {
    const [navBar, setNavBar] = useState({
        navExpanded: false
    });
    return (

        <Navbar bg="light" expand="lg" onToggle={() => setNavBar(prevState => ({ ...navBar, navExpanded: !prevState.navExpanded }))} expanded={navBar.navExpanded}>
            <Navbar.Brand href="/"><img
                alt=""
                src="../../Logo.png"
                width="60"
                height="30"
                className="d-inline-block align-top"
            />
                {'Checkline | ניהול תסקירים'}</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" >
                <Nav className="mr-auto">
                    <ul className="navbar-nav">
                        <li className="nav-item" onClick={() => setNavBar({ ...navBar, navExpanded: false })}>
                            <NavLink className="nav-link" to="/surveys">תסקירים</NavLink>
                        </li>
                        <li className="nav-item" onClick={() => setNavBar({ ...navBar, navExpanded: false })}>
                            <NavLink className="nav-link" to="/clients">לקוחות</NavLink>
                        </li>
                        <NavDropdown title='הנ"הש' id="basic-nav-dropdown">
                            <ul className="navbar-nav">
                                <li className="nav-item" onClick={() => setNavBar({ ...navBar, navExpanded: false })}>
                                    <NavLink className="nav-link" to="/invoice1">חשבוניות מס</NavLink>
                                </li>
                                <li className="nav-item" onClick={() => setNavBar({ ...navBar, navExpanded: false })}>
                                    <NavLink className="nav-link" to="/invoice2">חשבוניות עסקה</NavLink>
                                </li>
                                <li className="nav-item" onClick={() => setNavBar({ ...navBar, navExpanded: false })}>
                                    <NavLink className="nav-link" to="/receive">קבלות</NavLink>
                                </li>
                                <NavDropdown.Divider />
                                <li className="nav-item" onClick={() => setNavBar({ ...navBar, navExpanded: false })}>
                                    <NavLink className="nav-link" to="/client2">לקוחות חייבים</NavLink>
                                </li>
                            </ul>
                        </NavDropdown>
                        <li className="nav-item" onClick={() => setNavBar({ ...navBar, navExpanded: false })}>
                            <NavLink className="nav-link" to="/about" >הודות</NavLink>
                        </li>

                        {
                            user &&
                            <Fragment>
                                 <li className="nav-item" onClick={() => setNavBar({ ...navBar, navExpanded: false })}>
                                    <NavLink className="nav-link" to="/profile" >{user.uname}</NavLink>
                                </li>
                                <li className="nav-item" onClick={() => setNavBar({ ...navBar, navExpanded: false })}>
                                    <NavLink className="nav-link" to="/logout" >יציאה</NavLink>
                                </li>
                            </Fragment>
                        }

                        {
                            !user &&
                            <li className="nav-item" onClick={() => setNavBar({ ...navBar, navExpanded: false })}>
                                <NavLink className="nav-link" to="/login" >כניסה</NavLink>
                            </li>
                        }


                    </ul>
                </Nav>
             {/* <Form inline>
                    <FormControl type="text" placeholder="חיפוש" className="mr-sm-2"  />
                    <Button variant="outline-secondary">חפש</Button>
             </Form>*/}
            </Navbar.Collapse>
        </Navbar>
    )
};

export default NavBar;