import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { getSurveys, printSurvey } from '../services/surveyService';
import { paginate } from '../utils/paginate';
import { convertDate } from '../utils/convertDate';
import SearchBox from '../common/searchBox';
import Pagination from '../common/pagination';
import SurveysTable from './SurveysTable';



class Surveys extends Component {

    state = {
        surveys: [],
        dateFrom: new Date(),
        dateTo: new Date(),
        searchQuery: '',
        pageSize: 8,
        currentPage: 1,
        paginationRange: 6,
        paginationStartFrom: 1,
        sortColumn: { path: 'CustomerName', order: 'asc' }
    };

    async componentDidMount() {
        await this.getServerData();
    };

    handleSerch = query => {
        this.setState({ searchQuery: query, currentPage: 1 });
    };

    handlePrint = async survey => {
        const id = survey.ID.split('/')[0];
        const sertificate = survey.ID.split('/')[1];
        const {CustomerID} = this.props.user;
        await printSurvey(id, sertificate, CustomerID);
    };

    handlePageChange = page => {
        this.setState({ currentPage: page });
    };

    handleSort = sortColumn => {
        this.setState({ sortColumn });
    }

    handleNext = () => {
        const { paginationStartFrom, paginationRange } = this.state;
        const nextPagination = paginationStartFrom + paginationRange - 1;
        this.setState({
            paginationStartFrom: nextPagination,
            currentPage: nextPagination
        });
    };


    handlePrev = () => {
        const { paginationStartFrom, paginationRange, currentPage } = this.state;
        if (currentPage > paginationRange - 1) {
            const prevPagination = paginationStartFrom - paginationRange + 1;
            this.setState({
                paginationStartFrom: prevPagination,
                currentPage: prevPagination
            });
        }
    };

    handleChangeDateFrom = async dateFrom => {
        this.setState({
            dateFrom
        }, async () => { await this.getServerData() });
        // pass function as a callback, for executed after state change occurs (setState() does not immediately mutate this.state)
        // if call function after setState instead pass as a callback its still see old date value
    };

    handleChangeDateTo = async dateTo => {
        this.setState({
            dateTo
        }, async () => { await this.getServerData() });
    };

    getServerData = async () => {
        const { dateFrom, dateTo } = convertDate(this.state.dateFrom, this.state.dateTo);
        const surveys = await getSurveys('', 0, dateFrom, dateTo);
        this.setState({ surveys });
    };

    getPageData = () => {
        const { pageSize, currentPage, searchQuery, surveys: allsurveys, sortColumn } = this.state;
        let filtered = allsurveys;
        if (searchQuery) {
            filtered = allsurveys.filter(survey => survey.CustomerName.toLowerCase().startsWith(searchQuery.toLowerCase()));
        };
        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
        const surveys = paginate(sorted, currentPage, pageSize);
        return { totalCount: filtered.length, data: surveys };
    };

    render() {
        const { length: count } = this.state.surveys;
        if (count === 0) {
            return <div className='row'>
                <div className='col'>
                    <DatePicker selected={this.state.dateFrom} onChange={this.handleChangeDateFrom} dateFormat='dd/MM/yyyy' className='form-control my-3' />
                    {' - '}
                    <DatePicker selected={this.state.dateTo} onChange={this.handleChangeDateTo} dateFormat='dd/MM/yyyy' className='form-control my-3' />
                    <p>אין תסקירים</p>
                </div>
            </div>
        }
        const { pageSize, currentPage, searchQuery, paginationRange, paginationStartFrom, sortColumn } = this.state;
        const { totalCount, data: surveys } = this.getPageData();

        return (
            <div className='row'>
                <div className='col'>
                    <DatePicker selected={this.state.dateFrom} onChange={this.handleChangeDateFrom} dateFormat='dd/MM/yyyy' className='form-control my-3' />
                    {' - '}
                    <DatePicker selected={this.state.dateTo} onChange={this.handleChangeDateTo} dateFormat='dd/MM/yyyy' className='form-control my-3' />
                    <p>סה"כ {totalCount} תסקירים</p>
                    <SearchBox value={searchQuery} onChange={this.handleSerch} />
                    <SurveysTable surveys={surveys} onPrint={this.handlePrint} onSort={this.handleSort} sortColumn={sortColumn} />
                    <Pagination itemsCount={totalCount} pageSize={pageSize} onPageChange={this.handlePageChange}
                        currentPage={currentPage} paginationRange={paginationRange} onNext={this.handleNext} onPrev={this.handlePrev}
                        paginationStartFrom={paginationStartFrom} />
                </div>
            </div>
        );
    };
};

Surveys.propTypes = {
    user: PropTypes.object.isRequired
};

export default Surveys;