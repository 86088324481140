import React from 'react';

const Select = ({ name, label, options, error, textProperty, valueProperty, ...rest }) => {

    return (
        <div className='form-group'>
            <label htmlFor={name}>{label}</label>
            <select name={name} id={name} {...rest} className='form-control'>
                <option value='' />
                {options.map(option => (
                    <option key={option[valueProperty]} value={option[valueProperty]}>{option[textProperty]}</option>
                ))}
            </select>
            {error && <div className='alert alert-danger'>{error}</div>}
        </div>
    );
};

Select.defaultProps = {
    textProperty: 'label',
    valueProperty: 'id'
}

export default Select;