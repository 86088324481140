import auth from './authService';
import {apiUrl, magicAppName, endPoint} from '../../config.json';

export const getClients = async () => {
    const res = await fetch(endPoint + 'Clients&Arguments=-N1&CTX=' + auth.getCTX());
    const data = await res.json();
    return data;
};

export const getClient = async (IDCustomer) => {
    const res = await fetch(endPoint + `Clients&Arguments=-N1,-N${IDCustomer}&CTX=` + auth.getCTX());
    const data = await res.json();
    return data;
};

export const saveClient = async (client) => {
    const formData = new FormData();
    formData.append('appname', magicAppName);
    formData.append('prgname', 'Clients');
    formData.append('CTX', auth.getCTX());
    formData.append('Arguments', `-N2,-N${client.IDCustomer}`);
    formData.append('CustomerName', client.CustomerName);
    formData.append('ContactName', client.ContactName);
    formData.append('Phone1', client.Phone1);
    formData.append('Phone2', client.Phone2);
    formData.append('Phone3', client.Phone3);
     await fetch(apiUrl, {
        method: 'POST',
        body: formData
    });
   // const data = await res.text();
};
