import React from 'react';
import Joi from 'joi-browser';
import auth from '../services/authService';
import Form from '../common/form';


class Login extends Form {

    state = {
        data: {
            uname: '',
            upass: ''
        },
        errors: {}  // same structure as data
    };

    schema = {
        uname: Joi.string().required().label('שם משתמש'),
        upass: Joi.string().required().label('סיסמה')
    };

    doSubmit = async () => {
        const { data } = this.state;
        await auth.login(data.uname, data.upass);
    };

    doChange = () => {};

    render() {
        return (
            <div >
                <h5>כניסה</h5>
                <form onSubmit={this.handleSubmit}>
                    {this.renderInput('uname', 'שם משתמש')}
                    {this.renderInput('upass', 'סיסמה', 'password')}
                    {this.renderButton('כניסה')}
                </form>
            </div>
        );
    };
}


export default Login;