import React from 'react';

const PlainText = ({ name, label, ...rest }) => {  // in ...rest : value, type
    return (
        <div className="form-group">
            {label && <label htmlFor={name}>{label}</label>}
            <input
                {...rest}
                name={name}
                id={name}
                className="form-control-plaintext"
                readOnly
            />
        </div>);
};

export default PlainText;