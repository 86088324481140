import React, { Component } from 'react';
import Joi from 'joi-browser';
import Input from './input';
import Select from './select';
import PlainText from './plainText';

class Form extends Component {

    validateProperty = ({ name, value }) => {  // for one input
        const obj = { [name]: value };
        const schema = { [name]: this.schema[name] };
        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null;
    };


    validate = () => {  // for form
        const options = { abortEarly: false };
        const { error } = Joi.validate(this.state.data, this.schema, options);  // 'abortEarly' for keep validate rest inputs
        if (!error) return null;
        const errors = {};
        // eslint-disable-next-line
        for (let item of error.details) { errors[item.path[0]] = item.message; }
        return errors;
    };


    handleSubmit = e => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors || {} }); // if function 'validate' return 'null' update with empty object
        if (errors) return;
        this.doSubmit();
    };

    handleChange = ({ currentTarget: input }) => {
        // validate onchange input value
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
        // update state from inputs
        const data = { ...this.state.data };
        data[input.name] = input.value;
        this.setState({data, errors});
        this.doChange(input);
    };

    renderButton(label) {
        return (
            <button className="btn btn-primary">
                {label}
            </button>);
    };

    renderInput(name, label, type = 'text') {
        const {data, errors} = this.state;
        return (
            <Input type={type} name={name} value={data[name]} label={label} onChange={this.handleChange} error={errors[name]} />
        );
    };

    renderPlainText (name, label, type = 'text') {
        const {data} = this.state;
        return (
            <PlainText type={type} name={name}  value={data[name]} label={label}/>
        );
    };

    renderSelect(name, label, options, textProperty, valueProperty) {
        const {data, errors} = this.state;
        return (
            <Select name={name} value={data[name]} label={label} options={options} onChange={this.handleChange}
                error={errors[name]} textProperty={textProperty} valueProperty={valueProperty} />
        );
    };


};

export default Form;