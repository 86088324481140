import React from 'react';

const About = () => {

    return (
        <div >
            <h5>הודות</h5>
            <p>תוכנה לבודקים מוסמכים</p>
            <p>
                <strong>גירסה : </strong> 1.0.0
            </p>
        </div>
    );
}


export default About;