import React from 'react';
import TableHeader from './tableHeader';
import TableBody from './tableBody';

const Table = ({ data, columns, sortColumn, onSort, idProperty }) => {

    return (
        <div className='table-responsive'>
            <table className="table">
                <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
                <TableBody data={data} columns={columns} idProperty={idProperty} />
            </table>
        </div>);
}

export default Table;