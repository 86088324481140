import React from 'react';
import Joi from 'joi-browser';
import PropTypes from 'prop-types';
import Form from '../common/form';
import auth from '../services/authService';

class Profile extends Form {

    state = {
        data: {
            currentUser: '',
            currentCompany: '',
        },
        companies: [],
        errors: {},  // same structure as data object
    };

    schema = {
        currentCompany: Joi.string().required().label('חברה לעבודה'),
    };

    async componentDidMount() {
        const user  = await auth.getCurrentUser();
        const companies = await auth.getCompanies();
        this.setState(
            {
                companies,
                data: {
                    currentUser: user.uname,
                    currentCompany: user.CustomerID
                }
            }
        );
    };

    doChange = async (input) => {
        if (input.value) {
            await auth.setINI(input.value);
            await this.props.onChange();
        };
    };

    render() {
        const {data, companies} = this.state;
        return (
            <div>
                {data.currentUser}
                {this.renderSelect('currentCompany', 'חברה', companies)}
            </div>
        );
    };
}

Profile.propTypes = {
    onChange: PropTypes.func.isRequired
};

export default Profile;